import Register from '@/util/Purchase';

export default {
	data() {
		return {
			validator: new Register(),
			sponsorInfo: {
				id: '',
				name: '',
			},
		};
	},
	methods: {
		getSponsorInfo(sponsorId) {
			return this.validator.getSponsorInfo(sponsorId)
				.then((response) => response.response)
				.catch((error) => Promise.reject(error));
		},
		getSponsorData() {
			let sponsorId = 0;
			let isFromInput = false;
			if (this.$user.auth() === true) {
				sponsorId = this.$user.details().id;
			} else if (this.$user.auth() === false) {
				sponsorId = this.$replicated.replicatedSponsor();
				if (!sponsorId) {
					isFromInput = true;
					sponsorId = this.getStepInformation('RegisterPersonalInformation').sponsor;
				}
			}

			if (sponsorId) {
				this.getSponsorInfo(sponsorId).then((response) => {
					Object.keys(this.sponsorInfo).forEach((value) => {
						this.sponsorInfo[value] = response[value];
					});
					this.sponsorInfo.is_from_input = isFromInput;
				});
			}
			this.sponsorInfo.is_from_input = isFromInput;

			return this.sponsorInfo;
		},
	},
};
