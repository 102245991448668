import { REGISTER_STEPS as flowSteps } from '@/settings/Wizard';
import { REPLICATED_LEGS_LINK } from '@/settings/Register';
import {
	Addresses, Cart as CartMsg, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, PhoneVerification, Products, Profile, Purchase, Store, Users, Validations,
} from '@/translations';
import Cart from '@/util/Cart';
import Register from '@/util/Purchase';
import Captcha from '@/mixins/Captcha';
import Steps from './Steps';
import store from '../store';

export default {
	messages: [Addresses, CartMsg, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, PhoneVerification, Products, Profile, Purchase, Store, Users, Validations],
	mixins: [Captcha, Steps],
	data() {
		return {
			createCart: new Cart(),
			removeCart: new Cart(),
			validator: new Register(),
			preparing: 0,
		};
	},
	computed: {
		steps() {
			const steps = [];
			Object.keys(flowSteps).forEach((stepName) => {
				if (flowSteps[stepName].innerSteps) {
					steps.push(...flowSteps[stepName].innerSteps);
				}
				steps.push({ [stepName]: flowSteps[stepName] });
			});
			return steps;
		},
		stepNames() {
			return this.steps.map((step) => Object.keys(step)[0]);
		},
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
		currentModule() {
			const [mod] = this.$route.matched;
			return mod.name;
		},
		currentValidation() {
			return this.getStepValidationByIndex(this.currentStep);
		},
		isLastStep() {
			return this.currentStep === this.steps.length - 1;
		},
		cartId() {
			try {
				return store.getters.getStepInformation('cartId');
			} catch (error) {
				return '';
			}
		},
		cartRemovalResponse() {
			try {
				const { response } = this.removeCart.data.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
	},
	methods: {
		async setNewCartId(cartId) {
			if (cartId !== '') {
				await this.removeCart.removeCart(cartId);
				if (typeof this.cartRemovalResponse.cartId !== 'undefined' && cartId !== this.cartRemovalResponse.cartId) {
					this.saveCart(this.cartRemovalResponse.cartId);
				} else {
					await this.createCart.createCart();
					this.saveCart(this.createCart.data.response.data.response.cart_id);
				}
			}
		},
		validateStep(payload, convertToFormData = false) {
			if (this.validating) {
				return null;
			}
			return this.getCaptchaToken('register').then((token) => {
				payload['g-recaptcha-response'] = token;
				payload.cart_id = this.currentModule === 'Purchase' ? '' : this.cartId;
				if (REPLICATED_LEGS_LINK.includes(this.$replicated.replicatedPage())) {
					const positionData = this.getStepInformation('position');
					if (positionData !== '') {
						payload.leg = positionData.leg;
						payload.parent = positionData.parent;
					}
				} else {
					const positionData = this.getRegisterPositionValues();
					if (Object.keys(positionData).length) {
						payload.leg = positionData.placement;
						payload.parent = positionData.parent;
					}
				}
				const finalPayload = convertToFormData ? this.objectToFormData(payload) : payload;
				return this.validator.validate(finalPayload, this.currentModule)
					.then((response) => response)
					.catch((error) => Promise.reject(error));
			});
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
		saveStep(data, goToNext = true, emitSaved = true) {
			const { step } = data;
			return this.storeStepInfo(data, step).then(() => {
				if (emitSaved) {
					this.$emit('saved');
				}
				if (goToNext) {
					this.nextStep();
				}
			}).catch(() => {});
		},
		storeStepInfo(data, validation) {
			const step = this.getStepByValidation(validation);
			const payload = {};
			payload[Object.keys(step)[0]] = data;
			return store.dispatch('saveInfo', payload);
		},
		getStepInformation(stepName) {
			const currentStep = typeof stepName === 'undefined' ? this.$route.name : stepName;
			return store.getters.getStepInformation(currentStep);
		},
		saveCart(cartId) {
			return store.dispatch('saveCart', cartId);
		},
		removeInfo(resetStepNumber, removeCartId) {
			return store.dispatch('removeInfo', { resetStepNumber, removeCartId }).catch(() => {});
		},
		getStepByIndex(index) {
			return this.steps[index];
		},
		getStepNameByIndex(index) {
			return this.stepNames[index];
		},
		getStepValidationByIndex(index) {
			try {
				return this.getStepByIndex(index)[this.getStepNameByIndex(index)].validation;
			} catch (error) {
				return false;
			}
		},
		getStepValidationByName(stepName) {
			try {
				return this.getStepByName(stepName)[stepName].validation;
			} catch (error) {
				return false;
			}
		},
		getStepByName(stepName) {
			return this.steps.find((step) => Object.keys(step)[0] === stepName);
		},
		getStepByValidation(validation) {
			return this.steps.find((step) => step[Object.keys(step)[0]].validation === validation);
		},
		// This method sets the correct position for register whenever the user enters the website using a replicated site (signup-left, signup-right)
		async updateCustomRegisterLeg(replicatedPage) {
			if (replicatedPage && ['signup-left', 'signup-right'].includes(replicatedPage)) {
				this.setRegisterPositionValues({});
				const placement = replicatedPage === 'signup-left' ? 'left' : 'right';
				await this.$store.dispatch('position/onlyRegisterPosition', { placement });
				await store.dispatch('setPositionForRegister', { leg: placement, parent: null, spillover: true });
			}
		},
	},
};
