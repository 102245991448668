import store from '../store';

export default {
	computed: {
		currentStep() {
			return store.state.currentStep;
		},
	},
	methods: {
		initializeStepNumber(stepNumber) {
			store.dispatch('saveStepNumber', stepNumber);
		},
		nextStep() {
			this.goToStep(this.currentStep + 1);
		},
		goToStep(step) {
			store.dispatch('saveStepNumber', step);
		},
	},
};
